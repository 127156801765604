import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import "./App.css";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Auth from "./components/Auth/Auth";
import { useLocation, useRoutes, Navigate } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import * as userStore from "./recoil/atoms/user";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import BookingPage from "./Pages/user/BookingPage";
import Experiment from "./Pages/admin/Experiment";
import Users from "./Pages/admin/Users";
// import WatchStream from "./Pages/watchStream/WatchStream";
import PageNotFound from "./Pages/PageNotFound";
// import streamListState from "./recoil/atoms/streamList";
import WatchStream from "./Pages/user/streams/WatchStream"


const theme = createTheme({
	typography: {
		fontFamily:
			"'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
	},

	palette: {
		mode: "light",
		primary: {
			main: "#0A6ACB"
		}
	}
});

function App() {
	const setUser = useSetRecoilState(userStore.state);
	const location = useLocation();
	// const experimentData = useRecoilValue(streamListState);

	useEffect(() => {
		userStore.methods.refreshUsingAPI(setUser);
	}, [setUser]);

	function RedirectToYouTube() {
		useEffect(() => {
			window.location.href = "https://youtu.be/UIcQriFF7m4/";
		}, []);
	
		return null; // Return nothing as the redirection is handled.
	}

	const routes = useRoutes([
		{ path: "auth/*", element: <Auth /> },
		{ path: "/*", element: <Dashboard /> },
		{ path: "/admin/Experiment", element: <Experiment/> },
		{ path: "/admin/Users", element: <Users/> },
		{path: "/user/BookingPage", element: <BookingPage/>},
		{path: "*", element: <PageNotFound/>},
		{path: "/stream", element: <WatchStream />},
		{path:"/demo", element: <RedirectToYouTube />}
	]);

	const key = location.pathname.startsWith("/auth") ? "auth" : "dashboard";

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider>
				<CssBaseline />
				<AnimatePresence mode="wait">
					{React.cloneElement(routes, { key })}
				</AnimatePresence>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
